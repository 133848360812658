import axios from "axios";
import ReactDOM from 'react-dom';
import { getToken } from '../utils/handleToken'
import IonCardIcon from "../assets/images/logo_ion_pay.png";
import ErrorMessage from "../components/ErrorMessage";


const baseURL = process.env.REACT_APP_API_BASE_URL;

export const connectionApi = axios.create({
  baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
});

connectionApi.interceptors.request.use(
  async (config) => {

    if (config?.url !== 'authorization/oauth/token') {

      let headersConfig = {
        Authorization: await getToken(),
        Accept: 'application/json;charset=UTF-8',
      };

      config.headers = headersConfig;

    }


    return config;
  },
  (error) => Promise.reject(error)
);

connectionApi.interceptors.response.use(
  async (response) => {

    return response;
  },
  async (error) => {

    if (error?.response?.status !== 400 && error?.response?.status !== 404) {

      const myElement = (
        <>
          <div id="particles-js" className="bg-gradient">
            <canvas className="particles-js-canvas-el"></canvas>
          </div>
          <div className="container-login">
            <div className="container d-flex justify-content-center height-100vh">
              <div class="row align-self-center" >
                <div >
                  <div>
                    <a href="/">
                      <img
                        src={IonCardIcon}
                        alt="logo icon"
                        className="card-logo-icon"
                      />
                    </a>
                  </div>
                  <div>
                    <a href="/">
                      <div className='containerName' >
                        <span className="contentPname" >ION</span>
                        <span className="contentSname">PAY</span>
                      </div>
                    </a>

                  </div>
                  <div

                    style={{ height: "250px" }}
                  >
                    <ErrorMessage text={`${error?.response?.data?.userMessage} Error Code: ${error?.response?.data?.errorCode}`} />

                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );

      ReactDOM.render(
        myElement,
        document.getElementById("root")
      );

    }
    throw error.response.data;
  }
);

export default connectionApi;


