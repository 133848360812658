import { createContext, useState } from 'react';

export const FirebaseRegistrationContext = createContext([]);

export function FirebaseRegistrationProvider({ children }) {
  const [firebaseRegistration, setFirebaseRegistration] = useState(undefined);

  return (
    <FirebaseRegistrationContext.Provider
      value={{ firebaseRegistration, setFirebaseRegistration }}
    >
      {children}
    </FirebaseRegistrationContext.Provider>
  );
}
