import { useHistory } from "react-router-dom";
import React, { useState } from "react";
import "../assets/bootstrap.css";
import "../styles/pages/Home.scss";
import IonCardIcon from "../assets/images/logo_ion_pay.png";
import ReCAPTCHA from "react-google-recaptcha";


export function Home() {

  const history = useHistory();
  const [message, setMessage] = useState("");
  const [isVerified, setVerified] = useState(false);

  function handleClick() {
    if (isVerified) {
      if (message && message !== "") {
        history.push(`/${message}`);
      }
    } else {
      alert("Você deve preencher o ReCaptcha!");
    }
  }

  return (
    <>
      <div id="particles-js" className="bg-gradient">
        <canvas className="particles-js-canvas-el"></canvas>
      </div>
      <div className="container-login">
        <div className="container d-flex justify-content-center height-100vh">
          <div className="row align-self-center container-card">
            <div className="col-lg-5 mx-auto">
              <div>
                <a href="/">
                  <img
                    src={IonCardIcon}
                    alt="logo icon"
                    className="card-logo-icon"
                  />
                </a>
              </div>
              <div>
                <a href="/">
                  <div className='containerName' >
                    <span className="contentPname" >ION</span>
                    <span className="contentSname">PAY</span>
                  </div>
                </a>

              </div>
              <div
                className="card card-dashboard card-shadow border-0 my-3 text-left"
                style={{ height: "250px" }}
              >
                <div className="card-body">
                  <div className="py-4 px-4 border-bottom">
                    <div className="form-group">
                      <label
                        htmlFor="order"
                        className="text-muted text-center w-100"
                      >
                        Requisição de pagamento
                      </label>
                      <input
                        type="text"
                        name="order"
                        id="order"
                        className="form-control text-center"
                        autoComplete="off"
                        label="false"
                        aria-required="true"
                        placeholder="Digite o código da requisição de pagamento"
                        maxLength="255"
                        size="255"
                        onChange={(e) => setMessage(e.target.value)}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ReCAPTCHA
                        sitekey={process.env.REACT_APP_RECAPTCHA}
                        onChange={() => setVerified(true)}
                      />
                    </div>
                    <div className="mt-3 text-center">
                      <button
                        className="btn btn-primary px-5"
                        style={{ backgroundColor: "#7260aa" }}
                        onClick={handleClick}
                      >
                        Continuar
                      </button>
                    </div>
                  </div>
                  <div className="text-center text-muted mt-3">
                    <span>Dúvidas ? Entre em contato com a </span>
                    <a className="link" href="https://www.ionsistemas.com.br/">ION</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
