import React from "react";

export default function NegativeMessage({ closeNegativeMessage }) {
  return (
    <div className="ui red small message">
      <i className="close icon" onClick={closeNegativeMessage}></i>
      <div className="header">Infelizmente seu pagamento foi recusado</div>
      <p>Tente novamente</p>
    </div>
  );
}
