import { v4 as uuidv4 } from 'uuid';
import connectionApi from './connectionApi';

const BASE_URL = '/pagamentos/requisicaoPagamento';

export async function getRequestPayment(paymentRequestId) {

  try {
    const response = await connectionApi.get(
      `${BASE_URL}/${paymentRequestId}`
    );
    return response;
  } catch (err) {
    console.error(err);
    return err.response;
  }

}

export async function postRequestpayment(paymentRequestId,body) {
  
  try {
    const response = await connectionApi.post(
      `${BASE_URL}/${paymentRequestId}`,{
        
          ...body,
          transactionId: uuidv4()
        
      }
    );
    return response;
  } catch (err) {
    console.error(err);
    return err.response;
    
  }
}

export async function postSubscribe(requestId, socketIdClientId) {

  try {
    return await connectionApi.post(`${BASE_URL}/subscribe`, {
      socketIoClientId: socketIdClientId,
      requestId: requestId
    } );
  } catch (err) {
    return err.response;
  }
}

export async function postUnsubscribe(requestId, socketIdClientId) {
  try {
    return await connectionApi.post( `${BASE_URL}/unsubscribe`, {
      socketIoClientId: socketIdClientId,
      requestId: requestId
    });
  } catch (err) {
    return err.response;
  }
}


