import { createContext, useState } from 'react';

export const NotificationContext = createContext([]);

export function NotificationProvider({ children }) {
  const [notificationFirebase, setNotificationFirebase] = useState('');

  return (
    <NotificationContext.Provider
      value={{ notificationFirebase, setNotificationFirebase }}
    >
      {children}
    </NotificationContext.Provider>
  );
}
