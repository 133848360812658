import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import { Home } from './pages/Home';
import MainPayment from "./pages/MainPayment";

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/:id" exact component={MainPayment} />
      </Switch>
    </BrowserRouter>
  );
}
