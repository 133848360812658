import React from "react";


export default function EmptyFieldsMessage({ closeEmptyMessage, text= 'Informe todos os campos'}) {
  return (
    <div className="ui yellow small message ">
      <i className="close icon" onClick={closeEmptyMessage}></i>
      <div className="header">{text}</div>
    </div>
  );
}
