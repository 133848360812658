import { parseCookies, destroyCookie,setCookie } from 'nookies';
import { encryptToken, decryptToken } from './crypto';

export function deleteToken() {
  const cookies = parseCookies();
  const clientToken = cookies[`ION_TOKEN_CLIENT_CREDENTION@IONPAY`];

  if (clientToken) {
    destroyCookie(undefined, `ION_TOKEN_CLIENT_CREDENTION@IONPAY`);
  }
}

export async function saveToken(data) {
  const access_token = data?.access_token.toString();
  const token_type = data?.token_type.toString();
  const clientToken = ` ${token_type} ${access_token}`;

   const token = await encryptToken(clientToken);

    deleteToken();
    setCookie(
        undefined,
        `ION_TOKEN_CLIENT_CREDENTION@IONPAY`,
        token,
        {
          maxAge: 60 * 60 * 24, // 1 day
          path: '/'
        }
      );
    
}

export async function getToken() {
  const cookies = parseCookies();
  const clientToken = cookies[`ION_TOKEN_CLIENT_CREDENTION@IONPAY`];

  if (clientToken) {
   const token = await decryptToken(clientToken);
   return token;
  }
}
