/* eslint-disable react-hooks/exhaustive-deps */
import {
  CheckCircleOutlined,
  CheckOutlined,
  CopyOutlined,
  LoadingOutlined,
  MobileOutlined,
  QrcodeOutlined
} from '@ant-design/icons';
import { useState, useEffect } from 'react';
import copy from 'copy-to-clipboard';
import {
  getRequestPayment,
  postSubscribe,
  postUnsubscribe
} from '../services/paymentRequestService';
import { ImageReturn } from './ImageReturn';
import styles from '../styles/components/DigitalWallet.module.scss';
import { io } from "socket.io-client";
import json5 from 'json5';
import { Spin } from 'antd';
import ErrorMessage from './ErrorMessage';


export function DigitalWallet({ requestId, data, statusRequestPayment }) {

  const [isRead, setIsRead] = useState(false);
  const [qrCodeImage, setQrCodeImage] = useState(undefined);
  const [canceled, setCanceled] = useState(false);
  const antIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;
  const [copyCode, setCopyCode] = useState(false);
  const [socketId, setSocketId] = useState(undefined)
  const [onHandleMessage, setOnHandleMessage] = useState(undefined)
  const [hasError, setHasError] = useState('');

  const socket = io(process.env.REACT_APP_PATH_SOCKET, {
    transports: ["websocket"],
    auth: {
      token: `Bearer ${data?.access_token}`
    }
  });

  useEffect(() => {
    if (onHandleMessage?.messageType.includes('PAYMENT_REQUEST')) {
      handleRequestPayment();
    }

  }, [onHandleMessage])

  const handleRequestPayment = async () => {
    const response = await getRequestPayment(requestId);
    if (response?.status === 200) {
      handleStatusRequest(response?.data);
    } else {
      setHasError(response?.data?.userMessage);
    }

  }

  useEffect(() => {

    socket.on('connect', async () => {

      const socketIdClientId = socket.id;
      setSocketId(socket.id)
      console.log('socket.id', socket.id)
      handleStatusRequest(statusRequestPayment);

      const response = await postSubscribe(requestId, socketIdClientId);

      if (response.status === 200) {

        setHasError('');

      } else {
        setHasError(response?.data?.userMessage);
      }

    });

    socket.on('connect_error', (error) => {
      console.log(`Authorization Error: ${error.message}. Additional Data ${JSON.stringify(error.data)}`)
    })

    socket.on('message', onMessage);

  }, []);

  const onMessage = (msg) => {

    let data;
    if (typeof msg === 'string' || msg instanceof String) {
      data = msg;

    } else {
      data = json5.parse(JSON.stringify(msg));
    }

    setOnHandleMessage(data);

  }

  const handleCopy = async (item) => {
    copy(item);
    setCopyCode(true);

  };

  const handleStatusRequest = (response) => {

    if (hasError === '') {

      switch (response?.paymentRequestStatus) {
        case 'PENDENTE':
          setQrCodeImage(response?.requestData);
          break;
        case 'CANCELADO':
          handleCanceled();
          break;
        case 'FINALIZADO':
          hasRead();
          break;
        default:
          break;
      }
    }
  };

  const hasRead = async () => {

    if (socketId) {
      await postUnsubscribe(requestId, socketId);
    }

    setIsRead(true);
  };

  const handleCanceled = async () => {

    if (socketId) {
      await postUnsubscribe(requestId, socketId);
    }

    setCanceled(true);
  };

  const showViewCode = () => {

    return canceled ? (
      <>
        <div>
          <div className={styles.containerMain}>
            <div className={styles.contentMain}>
              <div>
                <div className={styles.containerPayment}>
                  <div className={styles.contentSubText}>
                    <span>
                      O qrcode para pagamento expirou. Você pode realizar um novo pedido para que um novo qrcode seja gerado e você possa realizar o pagamento.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    ) : (
      <div>
        <div className={styles.containerMain}>
          <div className={styles.contentMain}>
            <div>
              <div className={styles.containerPayment}>
                <div className={styles.contentSubText}>
                  {!qrCodeImage ? (
                    <span>
                      Gerando o link para pagamento, é rapidinho. Você também
                      poderá acessar o link de pagamento nos detalhes deste
                      pedido, no menu Meus Pedidos. Quando o qrcode está
                      disponível:
                    </span>
                  ) : (
                    <span>
                      Pague com Pix em qualquer dia e a qualquer hora! O
                      pagamento é instantâneo, prático e pode ser feito em
                      poucos segundos. É muito rápido e seguro :
                    </span>
                  )}
                </div>
                <div className={styles.contentPix}>
                  {!qrCodeImage ? (
                    <div className={styles.containerButtonLoading}>
                      <Spin indicator={antIcon} />
                      <span>Aguardando código ...</span>
                    </div>
                  ) : (
                    <div>
                      <div className={styles.contentQrCode}>
                        <ImageReturn imageSource={qrCodeImage?.qrCode} />
                      </div>
                      <div className={styles.containerIcon}>
                        <div
                          onClick={() => handleCopy(qrCodeImage?.qrCodeText)}
                          onKeyPress={() => handleCopy(qrCodeImage?.qrCodeText)}
                          tabIndex={0}
                          role="button"
                          className={styles.contentIconClick}
                        >
                          {copyCode ? (<CheckOutlined style={{ color: 'green', }} />) : (<CopyOutlined />)}

                        </div>

                        <div>
                          {copyCode ? (<span className={styles.colorGreen}>Código copiado !</span>)
                            :
                            (<span>Copiar código </span>)}

                        </div>
                      </div>
                    </div>
                  )}

                  <div className={styles.contentText}>
                    <div className={styles.contentSubText}>
                      <MobileOutlined className={styles.contentIcon} />
                      abra o app do seu banco ou instituição financeira e entre
                      no ambiente Pix
                    </div>
                    <div className={styles.contentSubText}>
                      <QrcodeOutlined className={styles.contentIcon} />
                      escolha a opção pagar com qr code e escaneie o código
                    </div>

                    <div className={styles.contentSubText}>
                      <CheckCircleOutlined className={styles.contentIcon} />{' '}
                      confirme as informações e finalize a compra
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const digitalWallet = () => {
    return isRead ? paymentCompleted() : showViewCode();
  };

  const paymentCompleted = () => {
    return (
      <div className={styles.containerResult}>
        <div>
          <CheckOutlined className={styles.contentIconResult} />
        </div>

        <span className={styles.contentTextResult}>Pagamento realizado com sucesso !</span>
      </div>
    );
  };


  return <div className={styles.main}>{hasError !== '' ? (<ErrorMessage text={hasError} />) : digitalWallet()}</div>;
}
