import React from "react";

export default function RequestPaymentCanceled() {
  return (
    <div className="ui red small message">
      <div className="header">Requisição de pagamento cancelada</div>
      <p>
        Deseja pesquisar uma nova requisição ? <a href="/">Clique aqui</a>
      </p>
    </div>
  );
}
