import React from "react";

import "../styles/components/Message.scss";

export default function PositiveMessage({
  headerMessage,
  footerMessage,
  href,
}) {
  return (
    <div className="ui green small message">
      <div className="header">{headerMessage}</div>
      <p>
        {footerMessage} <a href={href}>Clique aqui</a>
      </p>
    </div>
  );
}
