import React, { useState } from 'react';
import empty from '../assets/images/logo.png';

import styles from '../styles/components/ImageReturn.module.scss';

export function ImageReturn({ imageSource }) {
  const [error, setError] = useState(imageSource === undefined);
  const onError = () => {
    setError(true);
  };

  return (
    <>
      {error ? (
        <img src={empty.src} alt="img" className={styles.img} />
      ) : (
        <img
          src={imageSource}
          alt="img"
          onError={onError}
          className={styles.img}
        />
      )}
    </>
  );
}
