import connectionApi from './connectionApi';
const BASE_URL = `/utilitarios/cep`;

export async function getCep(cep) {

    try {
      return await connectionApi.get(`${BASE_URL}/${cep}`,null);
    } catch (err) {
      console.error(err);
      return err;
    }
  }