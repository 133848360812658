import React from "react";
import "../src/styles/global.scss";
import "semantic-ui-css/semantic.min.css";
import IonCardIcon from "./assets/images/logo_ion_pay.png";
import Routes from "./routes";
import { FirebaseRegistrationProvider } from "./contexts/FireBaseRegistrationContext";
import { NotificationProvider } from "./contexts/NotificationContext";


function App() {
  const favicon = IonCardIcon;
  var link = document.querySelector("link[rel~='icon']");
if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.head.appendChild(link);
}
link.href = favicon;

  return (
    <FirebaseRegistrationProvider>
       <NotificationProvider>
    <div className="App">
      <Routes />
    </div>
    </NotificationProvider>
    </FirebaseRegistrationProvider>

  );
}

export default App;
