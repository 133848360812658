import CryptoJS from 'crypto-js';

const  SECRET_CRYPTO = 'teste';

export async function encryptToken(item) {
  return CryptoJS.AES.encrypt(item, SECRET_CRYPTO).toString();
}

export async function decryptToken(item) {

  return CryptoJS.AES.decrypt(item, SECRET_CRYPTO).toString(CryptoJS.enc.Utf8);
}

