import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export function Loading({ loading = false, children }) {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <Spin spinning={loading} indicator={antIcon}>
      {children}
    </Spin>
  );
}
