import connectionApi from './connectionApi';
import { saveToken } from '../utils/handleToken';

const BASE_URL = 'authorization/oauth/token';

function getAuthorizationBase64() {
    const client_Id = process.env.REACT_APP_CLIENT_ID;
    const client_Secret = process.env.REACT_APP_CLIENT_SECRET;
    const authorization = `${client_Id}:${client_Secret}`;

    return `Basic ${Buffer.from(authorization).toString('base64')}`;
}

export async function postAuthorizationClient() {

    const headersConfig = {
        Authorization: getAuthorizationBase64(),
        'x-ION-DeviceID': '118d2def-07a6-4a45-a59b-46c5bf249964'
    };

    try {
        const response = await connectionApi.post(BASE_URL, {}, {
            params: { grant_type: 'client_credentials' },
            headers: headersConfig,
        });

        const clientToken = response?.data

        saveToken(clientToken)

        return response?.data

    } catch (err) {

        console.error(err);
        return err.response;
    }

}


