import React from "react";

import "../styles/components/RequestPaymentNotFound.scss";

export default function RequestPaymentNotFound() {
  return (
    <div className="request-payment-not-found">
      <div className="ui yellow small message">
        <div className="header">Requisição de pagamento não encontrada</div>
        <p>
          Deseja pesquisar uma nova requisição ? <a href="/">Clique aqui</a>
        </p>
      </div>
    </div>
  );
}
