import React from "react";

import styles from "../styles/components/ErrorMessage.module.scss";

export default function ErrorMessage({text}) {
 
  return (
    <div className={styles.containerError}>
      <div className="ui yellow small message">
        <div className="header">{text}</div>
        <p>
          Deseja pesquisar uma nova requisição ? <a href="/">Clique aqui</a>
        </p>
      </div>
    </div>
  );
}
